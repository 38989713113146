import React from 'react'
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import {apiResourceStateToPropsUtils} from "../../react-utils/ApiResource";
import {connect} from "react-redux";

class ProductSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            keywords: '',
            productChoices: []
        }
    }

    handleProductSearchSubmit = evt => {
        evt.preventDefault();

        const endpoint = `${this.props.category.url}products/?page_size=200&search=${encodeURIComponent(this.state.keywords)}`;

        const toastId = toast.info(<FormattedMessage
            id="searching_product"
            defaultMessage="Searching..." />, {
            autoClose: false
        });

        this.props.fetchAuth(endpoint).then(json => {
            const productChoices = json.results;

            toast.dismiss(toastId);

            if (!productChoices.length) {
                toast.error(<FormattedMessage
                    id="no_products_found"
                    defaultMessage="No products found" />)
            }

            this.setState({
                productChoices
            })

            const selectedProduct = productChoices.length ? productChoices[0] : null;
            this.props.onSelectedProductChange(selectedProduct)
        });
    };

    handleSelectedProductChange = evt => {
        const product = this.state.productChoices.find(x => x.id.toString() === evt.target.value)
        this.props.onSelectedProductChange(product)
    }

    render() {
        const selectedProduct = this.props.selectedProduct;

        return <>
            <form onSubmit={this.handleProductSearchSubmit}>
                <div className="form-group">
                    <input autoComplete="off"
                           type="text"
                           id="search"
                           className="form-control"
                           placeholder="Palabras clave"
                           value={this.state.keywords}
                           onChange={evt => this.setState({keywords: evt.target.value})}
                    />
                </div>
            </form>
            <div className="form-group">
                <label htmlFor="product">Producto</label>
                <select size={10}
                        className="form-control"
                        id="product"
                        name="product"
                        required={true}
                        value={selectedProduct ? selectedProduct.id : ''}
                        onChange={this.handleSelectedProductChange}>
                    {this.state.productChoices.map(product => (
                        <option key={product.id} value={product.id}>{product.name}</option>
                    ))}
                </select>
            </div>
        </>
    }
}

function mapStateToProps(state) {
    const {fetchAuth} = apiResourceStateToPropsUtils(state);

    return {
        fetchAuth,
    }
}


export default connect(mapStateToProps)(ProductSearch)