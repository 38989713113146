import React, {Component} from 'react'
import {
    apiResourceStateToPropsUtils,
    filterApiResourceObjectsByType
} from "../../react-utils/ApiResource";
import {connect} from "react-redux";
import Select from "react-select";
import ProductSearch from "../../components/Product/ProductSearch";
import {toast} from "react-toastify";

class ProductDetailFuse extends Component {
    constructor(props) {
        super(props);
        const product = this.props.ApiResourceObject(this.props.apiResourceObject)

        this.state = {
            selectedCategory: product.category,
            selectedProduct: null
        }
    }

    handleCategoryChange = selectedChoice => {
        this.setState({
            selectedCategory: selectedChoice.category
        })
    }

    handleSelectedProductChange = selectedProduct => {
        this.setState({
            selectedProduct: selectedProduct
        })
    }

    handleSubmit = evt => {
        evt.preventDefault();
        const product = this.props.apiResourceObject
        const targetProduct = this.state.selectedProduct

        if (!targetProduct) {
            toast.error('Por favor seleccione un producto');
            return
        }

        if (targetProduct.id === product.id) {
            toast.error('Por favor seleccione un producto distinto al original');
            return
        }

        const payload = {
            product: targetProduct.id
        };

        const toastId = toast.info('Fusionando, por favor espere');

        this.props.fetchAuth(product.url + 'fuse/', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(json => {
            toast.dismiss(toastId);
            window.location.href = '/products/' + targetProduct.id
        })
    }

    render() {
        const product = this.props.apiResourceObject
        const categoryOptions = this.props.categories.map(category => {
            return {
                label: category.name,
                value: category.id,
                category: category
            }
        })

        return (
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                Fusionar producto
                            </div>
                            <div className="card-block">
                                <p>Producto a ser
                                    eliminado: <strong>{product.name} (ID: {product.id})</strong>
                                </p>

                                <p>Por favor seleccione el producto al cual
                                    asociar todas las entidades y otros objetos
                                    actualmente asociados al producto
                                    original.</p>

                                <div>
                                    <Select
                                        options={categoryOptions}
                                        value={this.state.selectedCategory.id}
                                        onChange={this.handleCategoryChange}
                                        clearable={false}
                                    />
                                </div>

                                <div className="mt-2">
                                    <ProductSearch
                                        category={this.state.selectedCategory}
                                        onSelectedProductChange={this.handleSelectedProductChange}
                                        selectedProduct={this.state.selectedProduct}
                                    />
                                </div>

                                <form onSubmit={this.handleSubmit}>
                                    <div className="mt-2">
                                        <input type="submit"
                                               className="btn btn-primary"
                                               value="Fusionar"/>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {ApiResourceObject, fetchAuth} = apiResourceStateToPropsUtils(state);
    const categories = filterApiResourceObjectsByType(state.apiResourceObjects, 'categories')

    return {
        ApiResourceObject,
        fetchAuth,
        categories
    }
}

export default connect(mapStateToProps)(ProductDetailFuse);