import React from 'react'
import Select from "react-select";

import {Modal, ModalBody, ModalHeader} from "reactstrap";
import { toast } from 'react-toastify';
import {
    apiResourceStateToPropsUtils, filterApiResourceObjectsByType,
} from "../../react-utils/ApiResource";
import {connect} from "react-redux";
import {settings} from "../../settings";

class EntityAssociateBundle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            bundleName: ''
        }
    }

    specsModalToggle = evt => {
        evt && evt.preventDefault()

        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
    }

    handleSubmit = evt => {
        if (!this.state.bundleName) {
            toast.error('Debe ingresar un nombre para el bundle')
        }

        const endpoint = settings.apiResourceEndpoints.bundles
        const payload = {
            name: this.state.bundleName
        }

        this.props.fetchAuth(endpoint, {method: 'POST', body: JSON.stringify(payload)}).then(json => {
            this.props.addBundle(json)
            this.setState({
                isModalOpen: false
            })
            this.props.onCreate(json)

        }).catch(err => {
            err.json().then(errorJson => {
                toast.error(errorJson.name[0])
            }).catch(() => {
                toast.error('Error desconocido al crear el bundle')
            })
        })
    }

    render() {
        const bundles = this.props.bundles
        const options = bundles.map(bundle => {
            return {
                label: bundle.name,
                value: bundle.id
            }
        })

        return <>
        <div className="form-group">
            <label htmlFor="bundle">Bundle</label>
            <div className="d-flex flex-row align-items-center">
                  <Select
                      options={options}
                      value={this.props.value}
                      onChange={this.props.onChange}
                      isClearable={true}
                      placeholder="Ninguno"
                  />
            <span className="ml-2">
                <button className="btn btn-success" onClick={this.specsModalToggle}>Agregar</button>
            </span>
            </div>
        </div>
            <Modal isOpen={this.state.isModalOpen} toggle={evt => this.specsModalToggle()} size="lg">
                <ModalHeader toggle={this.specsModalToggle}>
                  Agregar bundle
                </ModalHeader>
                <ModalBody>
                  <form onSubmit={this.handleSubmit}>
                      <input
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Nombre del bundle"
                          value={this.state.bundleName}
                          onChange={evt => this.setState({bundleName: evt.target.value})}
                      />
                      <input type="submit" className="btn btn-primary mt-2" value="Crear" />
                  </form>
                </ModalBody>
              </Modal>
        </>
    }
}

function mapStateToProps(state) {
  const {fetchAuth} = apiResourceStateToPropsUtils(state);
  const bundles = filterApiResourceObjectsByType(state.apiResourceObjects, 'bundles')

  return {
    fetchAuth,
    bundles
  }
}

function mapDispatchToProps(dispatch) {
    return {
        addBundle: bundle => {
              return dispatch({
                type: 'addApiResourceObject',
                apiResource: bundle
              })
            }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EntityAssociateBundle)