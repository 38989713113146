import React from 'react';
import {FormattedMessage} from "react-intl";
import {NavLink} from "react-router-dom";
import './StoreDetailMenu.css';
import {settings} from "../../settings";
import {apiResourceStateToPropsUtils} from "../../react-utils/ApiResource";
import {connect} from "react-redux";

class StoreDetailMenu extends React.Component {
  download_matching_report = () => {
    const store = this.props.retailer

    const endpoint = `${settings.apiResourceEndpoints.stores}${store.id}/matching_report/`
    this.props.fetchAuth(endpoint).then(res => {
      window.location = res.url
    })
  }

  render() {
    const store = this.props.retailer

    return (<div className="col-sm-6 col-md-4">
      <div className="card">
        <div className="card-header"><FormattedMessage id="options"
                                                       defaultMessage="Options"/>
        </div>
        <div className="card-block">
          <ul className="list-without-decoration subnavigation-links">
            {store.permissions.includes('view_store') &&
            <li><NavLink to={'/stores/' + store.id}>
              <button type="button" className="btn btn-link">
                <FormattedMessage id="general_information"
                                  defaultMessage="General Information"/>
              </button>
            </NavLink></li>
            }
            {store.permissions.includes('update_store_pricing') &&
            <li>
              <NavLink to={'/stores/' + store.id + '/update_pricing'}>
                <button type="button" className="btn btn-link">
                  <FormattedMessage id="update_pricing"
                                    defaultMessage="Update pricing"/>
                </button>
              </NavLink></li>
            }
            {store.permissions.includes('view_store_update_logs') &&
            <li>
              <NavLink to={'/stores/' + store.id + '/update_logs'}>
                <button type="button" className="btn btn-link">
                  <FormattedMessage id="update_logs"
                                    defaultMessage="Update logs"/>
                </button>
              </NavLink></li>
            }
            {store.permissions.includes('view_store_leads') &&
            <li>
              <NavLink to={'/leads/?stores=' + store.id}>
                <button type="button" className="btn btn-link">
                  <FormattedMessage id="leads_list"
                                    defaultMessage="Leads (list)"/>
                </button>
              </NavLink>
            </li>
            }
            {store.permissions.includes('view_store_leads') &&
            <li>
              <NavLink
                  to={'/leads/stats?grouping=category&stores=' + store.id}>
                <button type="button" className="btn btn-link">
                  <FormattedMessage id="leads_stats"
                                    defaultMessage="Leads (stats)"/>
                </button>
              </NavLink>
            </li>
            }
            {this.props.user.is_superuser &&
            <li>
              <NavLink to={'/entities/conflicts?stores=' + store.id}>
                <button type="button" className="btn btn-link">
                  <FormattedMessage id="conflicting_entities"
                                    defaultMessage="Conflicting entities"/>
                </button>
              </NavLink>
            </li>
            }
            {this.props.user.permissions.includes('solotodo.backend_list_ratings') &&
            <li>
              <NavLink to={'/ratings?stores=' + store.id}>
                <button type="button" className="btn btn-link">
                  <FormattedMessage id="ratings" defaultMessage="Ratings"/>
                </button>
              </NavLink>
            </li>
            }
            {store.permissions.includes('view_store_reports') &&
            <li>
              <button type="button" className="btn btn-link"
                      onClick={this.download_matching_report}>
                <FormattedMessage id="download_matching_report"
                                  defaultMessage="Descargar reporte de homologación"/>
              </button>
            </li>
            }
          </ul>
        </div>
      </div>
    </div>)
  }
}

function mapStateToProps(state) {
  const {fetchAuth} = apiResourceStateToPropsUtils(state);

  return {
    fetchAuth,
  }
}

export default connect(mapStateToProps)(StoreDetailMenu);